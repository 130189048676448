import ReactSlider from 'react-slider';
import styled from 'styled-components';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 10px;
    margin: 1em 0;
`;

export const StyledThumb = styled.div`
    height: 28px;
    line-height: 23px;
    width: 28px;
    text-align: center;
    background-color: ${props => props.theme.text};
    color: ${props => props.theme.background};
    border-radius: 50%;
    padding: 3px;
    cursor: grab;
    top: -7.5px;
`;

export const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => props.theme.accent};
    border-radius: 999px;
`;