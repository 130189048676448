import { useState } from "react";
import {
  RuleBody,
  RulesContainer,
  Card,
  RuleHeader,
  Chevron,
  CommunityRulesContainer,
} from "./CommunityTriggerTopics.styled";
import * as DOMPurify from "dompurify";
import { useGetTriggerTopicsQuery } from "./CommunityApi";
import LoadingPage from "../LoadingPage";
import Breadcrumbs from "../../elements/Breadcrumbs";

const CommunityTriggerTopics = () => {
  const [expandedRule, setExpandedRule] = useState(null);

  const { data: triggerTopics, isLoading } = useGetTriggerTopicsQuery();

  const expandRule = (index) => {
    if (expandedRule === index) setExpandedRule(null);
    else setExpandedRule(index);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <CommunityRulesContainer>
      <Breadcrumbs />
      <RulesContainer>
        <Card>
          <h1>Community Trigger Topics</h1>{" "}
        </Card>

        {(triggerTopics || []).map((rule, index) => (
          <Card key={index}>
            <RuleHeader onClick={() => expandRule(index)}>
              <h2>
                {index + 1}. {rule.header}
              </h2>
              <Chevron
                className={
                  expandedRule !== null && expandedRule === index
                    ? "expanded"
                    : ""
                }
              />
            </RuleHeader>
            <RuleBody
              className={
                expandedRule !== null && expandedRule === index
                  ? "expanded"
                  : ""
              }
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(rule.body),
              }}
            ></RuleBody>
          </Card>
        ))}
      </RulesContainer>
    </CommunityRulesContainer>
  );
};

export default CommunityTriggerTopics;
