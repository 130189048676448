import styled from "styled-components";
import { devices } from "../../media-breakpoints";

export const ArticleContainer = styled.main`
  display: grid;
  grid-template-areas:
    "hero"
    "sidebar1"
    "sidebar2";
  padding: 0.5em;

  @media only screen and (${devices.lg}) {
    padding-bottom: 2em;
    margin: 4em 2em 0;

    grid-template-areas:
      "hero sidebar1"
      "hero sidebar2";
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em 1em;
`;

export const TwoColumnLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (${devices.lg}) {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.$center ? "center" : "initial")};
  align-items: center;

  @media only screen and (${devices.lg}) {
    padding: 2em;
    width: ${(props) => `${(props.$gridColumns / props.$totalColumns) * 100}%`};
  }
`;
