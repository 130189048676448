import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsApiConfig } from "../../config";

export const lawApi = createApi({
  reducerPath: "lawApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${cmsApiConfig.apiUrl}/law/` }),
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (getAll = true, options) => `/countries?getAll=${getAll}`,
    }),
    getRegionsByCountryId: builder.query({
      query: (countryId, options) => `/countries/${countryId}/regions`,
    }),
    getLawsByCountryId: builder.query({
      query: (countryId) => `/countries/${countryId}/laws`,
    }),
    getLawsByRegionId: builder.query({
      query: (regionId) => `/regions/${regionId}/laws`,
    }),
  }),
});

export const {
  useLazyGetCountriesQuery,
  useGetCountriesQuery,
  useGetRegionsByCountryIdQuery,
  useGetLawsByCountryIdQuery,
  useGetLawsByRegionIdQuery,
  useLazyGetLawsByCountryIdQuery,
  useLazyGetRegionsByCountryIdQuery,
  useLazyGetLawsByRegionIdQuery
} = lawApi;
