import styled from "styled-components";
import SearchableDropdown from "../../elements/SearchableDropdown";
import { devices } from "../../media-breakpoints";
import { Link } from "react-router-dom";

export const CountryContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em 1em;
  width: 100%;
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.region {
    background: #321a1abf;
  }

  @media only screen and (${devices.lg}) {
    width: 33%;
  }
`;

export const CardHeader = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: inherit;
  }

  img {
    height: 50px;
  }
`;

export const LocationDropdown = styled(SearchableDropdown)`
  margin-top: 0.5em;
  max-width: 425px;
`;

export const PillContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10px, 100px));
  grid-gap: 1em; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CountryPill = styled(Link)`
  background-color: ${(props) => props.theme.background};
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 5px;
  transition: background-color ease-in-out 250ms;

  &:hover {
    background-color: ${(props) => props.theme.accentHoverSecondary};
    cursor: pointer;
  }
`;

export const EntryRequirementPill = styled.p`
  background-color: ${(props) => props.theme.background};
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid ${(props) => props.theme.accent};
  border-radius: 5px;
  transition: background-color ease-in-out 250ms;
  text-decoration: none;
`;

export const LawCard = styled(Card)`
  width: 100%;
`;

export const LawHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p,
  h2 {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const LawBody = styled.div`
  max-height: 0;
  transition: max-height 250ms ease-in-out;
  overflow: hidden;

  &.expanded {
    max-height: 100000px;
  }
`;

export const LawBodyHeader = styled.h3`
  margin: 8px 0;
`;

export const LawTextSectionHeader = styled.p`
  margin: 0;
`;

export const LawTextSection = styled.div`
  border-radius: 4px;
  background: #1a1a1a40;
  padding: 0.5em 1em;
  margin: 0.5em 0;
`;
