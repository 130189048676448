import { useEffect, useRef, useState } from "react";
import {
  Arrow,
  Dropdown,
  Option,
  Options,
  SelectedValue,
} from "./SearchableDropdown.styled";
import classNames from "classnames";

const SearchableDropdown = ({
  className,
  options,
  label,
  id,
  metadata,
  searchTags,
  selectedVal,
  handleChange,
  handleTextChange = undefined,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");

    handleChange(option[id]);
    handleTextChange !== undefined && handleTextChange(option[label]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    if (e.target.name === "searchTerm") e.target.select();
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) {
      var selectedOption = options.find((opt) => opt[id] === selectedVal);
      return selectedOption[label];
    }

    return "";
  };

  const filter = (options) =>
    options.filter(
      (option) =>
        option[label].toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (searchTags &&
          option[id] !== -1 &&
          option.searchTags.some(
            (tag) => tag.indexOf(query.toLowerCase()) > -1
          )) ||
        (metadata || []).some(
          (metadataField) =>
            option[id] !== -1 &&
            option[metadataField].indexOf(query.toLowerCase()) > -1
        )
    );

  return (
    <Dropdown className={className}>
      <div className="control">
        <SelectedValue>
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleTextChange !== undefined &&
                handleTextChange(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        </SelectedValue>
        <Arrow className={classNames({ open: isOpen })}></Arrow>
      </div>

      <Options className={classNames({ open: isOpen })}>
        {filter(options).map((option, index) => {
          return (
            <Option
              onClick={() => selectOption(option)}
              className={classNames({ selected: option[id] === selectedVal })}
              key={`${id}-${index}`}
            >
              {option[label]}
            </Option>
          );
        })}
      </Options>
    </Dropdown>
  );
};

export default SearchableDropdown;
