/*

Usage Guidelines:

Background: Use the primary background color for the main background of your website in the respective mode (dark or light).

Text: Apply the text color for all content, ensuring good contrast and readability.

Accent: Utilize the accent colors (#f3b41c and #428fdf) for call-to-action buttons, links, and important elements to create a cohesive brand identity.

Secondary Accent: Use the secondary accent color for additional visual elements to maintain consistency.

Borders: Employ the border color for separating sections and creating visual hierarchy.

*/

export const darkMode = {
  name: "darkMode",
  label: "Dark mode",
  background: "#1a1a1abf",
  backgroundSolid: "#1a1a1a",
  text: "#fff",
  textSecondary: "#424242",
  linkHover: "#e1a516",
  border: "#505050",
  toggleButtonText: "Light mode",
  accent: "#f3b41c",
  accentHover: "#e1a516",
  accentSecondary: "#428fdf",
  accentDisabled: "#F3B41C80",
  accentHoverSecondary: "#326cbb",
  accentBorders: false,
};

export const lightMode = {
  name: "lightMode",
  label: "Light mode",
  background: "#f7f7f7f2",
  backgroundSolid: "#f7f7f7",
  text: "#333",
  textSecondary: "#333",
  linkHover: "#e1a516",
  border: "#d0d0d0",
  toggleButtonText: "Dark mode",
  accent: "#f3b41c",
  accentHover: "#e1a516",
  accentSecondary: "#428fdf",
  accentDisabled: "#F3B41C80",
  accentHoverSecondary: "#326cbb",
  accentBorders: false,
};

export const highContrastDark = {
  name: "highContrastDark",
  label: "High contrast (Dark)",
  background: "#000",
  backgroundSolid: "#000",
  text: "#fff",
  textSecondary: "#313131",
  linkHover: "#e1a516",
  border: "#fff",
  accent: "#ffb500",
  accentHover: "#ffca4b",
  accentSecondary: "#428fdf",
  accentDisabled: "#F3B41C80",
  accentHoverSecondary: "#326cbb",
  accentBorders: true,
};

export const highContrastLight = {
  name: "highContrastLight",
  label: "High contrast (Light)",
  background: "#fff",
  backgroundSolid: "#fff",
  text: "#000",
  textSecondary: "#000",
  linkHover: "#333",
  border: "#fff",
  accent: "#ffb500",
  accentHover: "#333",
  accentSecondary: "#428fdf",
  accentDisabled: "#F3B41C80",
  accentHoverSecondary: "#326cbb",
  accentBorders: true,
};

export const themes = {
  darkMode,
  lightMode,
  highContrastDark,
  highContrastLight,
};
