import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsApiConfig } from "../../config";

export const articleApi = createApi({
  reducerPath: "articleApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${cmsApiConfig.apiUrl}/article/` }),
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: (id) => (id === undefined ? "" : `/${id}`),
    }),
  }),
});

export const { useGetArticlesQuery } = articleApi;
