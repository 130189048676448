import styled from "styled-components";
import SearchableDropdown from "../../elements/SearchableDropdown";
import { devices } from "../../media-breakpoints";
import { Link } from "react-router-dom";
import { themes } from "../../themes";

export const CountriesContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em 1em;
  width: 100%;
  margin-bottom: 2em;
  margin-left: 0.5em;
  
  &:last-of-type {
    margin-bottom: 0;
  }

  &.region {
    background: #321a1abf;
  }

  @media only screen and (${devices.lg}) {
    width: 33%;
  }
`;

export const LocationDropdown = styled(SearchableDropdown)`
  margin-top: 0.5em;
  max-width: 425px;
`;

export const ShowAllCountriesContainer = styled.div`
  display: flex;
  flex-direction: row;

  label {
    margin-right: 0.5em;
  }
`;

export const PillContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10px, 100px));
  grid-gap: 1em; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CountryPill = styled(Link)`
  background-color: ${(props) => props.theme.background};
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 5px;
  transition: background-color ease-in-out 250ms;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.accentHoverSecondary};
    cursor: pointer;
    color: ${themes.lightMode.text};
  }

  img {
    height: 24px;
  }
`;
