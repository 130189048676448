import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  Card,
  Column,
  ArticleContainer,
  TwoColumnLayout,
} from "./Article.styled";
import LoadingPage from "../LoadingPage";
import { useGetArticlesQuery } from "./ArticleApi";
import { renderImage } from "../../helpers/imageHelper";
import useWindowDimensions from "../../helpers/screenSizeHelper";
import Breadcrumbs from "../../elements/Breadcrumbs";

const Article = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetArticlesQuery(id);
  const { screenWidth } = useWindowDimensions();

  const article = (data || []).find(
    (article) => article.id === Number.parseInt(id)
  );

  if (isLoading || article === undefined) return <LoadingPage />;

  // const headlines = article.bodySections.filter(
  //   (section) => section.sectionType === 0
  // );

  return (
    <ArticleContainer>
      <Card>
        <Breadcrumbs />
        <div>
          <h1>{article.name}</h1>
          {article.bodySections.map((bodySection, idx) => {
            if (bodySection.sectionType === 0) {
              return <h2 key={idx}>{bodySection.content}</h2>;
            } else if (bodySection.sectionType === 1) {
              return (
                <div
                  key={idx}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(bodySection.content),
                  }}
                ></div>
              );
            } else if (bodySection.sectionType === 2) {
              return (
                <TwoColumnLayout key={idx}>
                  {bodySection.areas.map((area, areaIdx) => (
                    <Column
                      key={`area-${areaIdx}`}
                      $totalColumns={bodySection.columnSpan}
                      $gridColumns={area.columnSpan}
                      $center={area.content.image !== null}
                    >
                      {area.content.richText !== null && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(area.content.richText),
                          }}
                        ></div>
                      )}
                      {area.content.image !== null &&
                        renderImage(
                          area.content.image,
                          screenWidth,
                          area.content.cropToUse
                        )}
                    </Column>
                  ))}
                </TwoColumnLayout>
              );
            }
            return null;
          })}
        </div>
      </Card>
    </ArticleContainer>
  );
};

export default Article;

/*

const portraitCrop = area.content.crops.find((crop) => crop.alias === "Portrait");

                            return <img src={cmsApiConfig.baseUrl><

*/
