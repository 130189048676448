import React from "react";
import {
  HeaderBar,
  BrandContainer,
  NavLinkContainer,
  NavItem,
  NavLink,
  HamburgerMenu,
  AccessibilityPageLink,
} from "./NavMenu.styled";
import AppRoutes, { Paths } from "../AppRoutes";
import { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import useWindowDimensions from "../helpers/screenSizeHelper";

const NavMenu = (props) => {
  const { screenWidth } = useWindowDimensions();
  const [isCollapsed, setIsCollapsed] = useState(
    screenWidth < 768 || window.scrollY > 60
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (setValue = undefined) => setIsMenuOpen(setValue);

  const checkIsCollapsed = () => {
    if (screenWidth < 768 || window.scrollY > 60) {
      setIsCollapsed(true);
    } else {
      toggleMenu(false);
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkIsCollapsed();
    };

    const handleResize = () => {
      checkIsCollapsed();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    //   window.removeEventListener("resize", handleResize);
    // };
  });

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <HeaderBar
      $isCollapsed={isCollapsed}
      $isOpen={isMenuOpen}
      onClick={() => isCollapsed && toggleMenu(!isMenuOpen)}
    >
      <HamburgerMenu
        $isCollapsed={isCollapsed}
        className={isMenuOpen ? "open" : ""}
      >
        &#9776;
      </HamburgerMenu>
      <BrandContainer $isCollapsed={isCollapsed} onClick={scrollToTop}>
        <Link to={Paths.HOME}>ADG</Link>
      </BrandContainer>
      <NavLinkContainer
        $isCollapsed={isCollapsed}
        className={isMenuOpen ? "open" : ""}
      >
        {AppRoutes.filter((route) => route.showInNav).map((route) => (
          <NavItem key={route.path} $isCollapsed={isCollapsed}>
            <NavLink to={route.path} onClick={() => setIsMenuOpen(false)}>
              {route.name}
            </NavLink>
          </NavItem>
        ))}
        <AccessibilityPageLink to={Paths.ACCESSIBILITY}>Accessbility</AccessibilityPageLink>
        {/* <ToggleTheme onClick={() => props.onToggleTheme()}>
          {theme.toggleButtonText}
        </ToggleTheme> */}
      </NavLinkContainer>
    </HeaderBar>
  );
};

export default NavMenu;
