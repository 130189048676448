import classNames from "classnames";
import styled from "styled-components";

const PrimaryButton = ({ children, disabled = false, onClick }) => {
  return (
    <AccentButton onClick={onClick} className={classNames({ disabled })}>
      {children}
    </AccentButton>
  );
};

const AccentButton = styled.button`
  background: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.textSecondary};
  margin-left: auto;
  border: none;
  transition: background 250ms ease-in-out, color 250ms ease-in-out;
  padding: 0.5em;
  text-decoration: none;
  display: inline-block;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.accentHover};
  }

  &.disabled {
    background: ${(props) => props.theme.accentDisabled};

    &:hover,
    &:focus {
      background: ${(props) => props.theme.accentDisabled};
      cursor: default;
    }
  }
`;

export default PrimaryButton;
