import {
  LoadingContainer,
  LoadingSpinner,
  LoadingText,
} from "./LoadingPage.styled";

const LoadingPage = () => {
  return (
    <LoadingContainer>
      <LoadingText>Loading</LoadingText>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default LoadingPage;
