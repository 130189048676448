import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsApiConfig } from "../../config";

export const communityApi = createApi({
  reducerPath: "communityApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${cmsApiConfig.apiUrl}/community/` }),
  endpoints: (builder) => ({
    getCommunityPage: builder.query({
      query: (getAll = true) =>
        '',
    }),
    getRules: builder.query({
      query: () =>
        `/rules`,
    }),
    getTriggerTopics: builder.query({
      query: () =>
        `/trigger-topics`,
    })
  }),
});

export const { useGetCommunityPageQuery, useGetRulesQuery, useGetTriggerTopicsQuery } = communityApi;
