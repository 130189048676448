import { styled } from "styled-components";
import { devices } from "../../media-breakpoints";

export const AccessibilityContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 2em;

  @media only screen and (${devices.lg}) {
    max-width: 75%;
  }

  @media only screen and (${devices.xl}) {
    max-width: 50%;
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em 1em 1em;
  margin: 0.5em 0;

  h2 {
    margin: 0.5em 0;
  }
  h3 {
    margin: 0.25em 0;
  }
`;
