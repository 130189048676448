import { useState } from "react";
import LoadingPage from "../LoadingPage";
import { useGetCountriesQuery, useLazyGetLawsByCountryIdQuery } from "./LawApi";
import {
  CountriesContainer,
  Card,
  LocationDropdown,
  ShowAllCountriesContainer,
  CountryPill,
  PillContainer,
} from "./Countries.styled";
import { Paths } from "../../AppRoutes";
import Breadcrumbs from "../../elements/Breadcrumbs";

const Countries = () => {
  const [showAllCountries, setShowAllCountries] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  const { data: dataCountries, isLoading } =
    useGetCountriesQuery(showAllCountries);

  const [getCountryLaws] = useLazyGetLawsByCountryIdQuery();
  const toggleShowAllCountries = () => {
    setShowAllCountries(!showAllCountries);
  };

  const onChangeCountry = (e) => {
    setSelectedCountry(e);
  };

  if (isLoading) return <LoadingPage />;

  const countries = [
    { id: -1, name: "Choose an option" },
    ...[...(dataCountries || [])],
  ];

  const matchingCountries =
    currentSearchTerm.length === 0 || selectedCountry === -1
      ? dataCountries || []
      : (dataCountries || []).filter((country) =>
          country.name.toLowerCase().includes(currentSearchTerm.toLowerCase())
        );

  return (
    <CountriesContainer>
      <Breadcrumbs />
      <Card>
        <h1>Countries</h1>
        <ShowAllCountriesContainer>
          <label htmlFor='showAllCountries'>Show countries without data</label>
          <input
            type='checkbox'
            defaultChecked={showAllCountries}
            value={showAllCountries}
            onChange={() => toggleShowAllCountries()}
            id='showAllCountries'
            name='showAllCountries'
          />
        </ShowAllCountriesContainer>
        <LocationDropdown
          options={countries || []}
          label='name'
          id='id'
          metadata={["twoDigitIso", "threeDigitIso"]}
          searchTags={true}
          selectedVal={selectedCountry}
          handleChange={(val) => onChangeCountry(val)}
          handleTextChange={(val) => setCurrentSearchTerm(val)}
          addDefaultOption={true}
        />
      </Card>
      <PillContainer>
        {(matchingCountries || []).map((country) => (
          <CountryPill
            key={country.id}
            to={`${Paths.COUNTRIES}/${encodeURIComponent(country.name)}`}
            onClick={() => getCountryLaws(country.id)}>
            {country.name}{' '}
            <img
              src={`${process.env.PUBLIC_URL}/images/flags/${country.twoDigitIso}.png`}
              alt={"The flag of " + country.name}
              loading='lazy'
            />
          </CountryPill>
        ))}
      </PillContainer>
    </CountriesContainer>
  );
};

export default Countries;
