import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) =>
    props.$isCollapsed ? (props.$isOpen ? "120px" : "120px") : "100%"};
  height: ${(props) => (props.$isOpen ? "unset" : "60px")};
  background: ${(props) =>
    props.$isCollapsed ? props.theme.backgroundSolid : props.theme.background};
  display: flex;
  flex-direction: ${(props) => (props.$isOpen ? "column" : "row")};
  /* box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%); */
  box-shadow: ${(props) =>
    props.$isCollapsed
      ? "2px 2px 4px 0 rgb(0 0 0 / 50%);"
      : "0 2px 4px 0 rgb(0 0 0 / 50%);"};
  transition: background 250ms ease-in-out, width 0.25s ease-in-out;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 120px;
    background: ${(props) => props.theme.backgroundSolid};
  }
`;

export const BrandContainer = styled.div`
  display: ${(props) => (props.$isCollapsed ? "none" : "block")};
  color: ${(props) => props.theme.text};
  transition: color 250ms ease-in-out;
  margin: 0 2em;
  padding: 1em;

  a {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    a {
      color: ${(props) => props.theme.linkHover};
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLinkContainer = styled.div`
  display: ${(props) => (props.$isCollapsed ? "none" : "flex")};
  flex-direction: row;
  width: 100%;
  max-height: ${(props) => (props.$isCollapsed ? "0" : "unset")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  &.open {
    max-height: 2500px; /* Adjust the value as needed */
    display: flex;
    flex-direction: column;

    div {
      text-align: center;
    }

    button {
      margin: 0.5em auto;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.div`
  border-right: ${(props) =>
    props.$isCollapsed ? "0" : "1px solid" + props.theme.text};
  border-bottom: ${(props) =>
    props.$isCollapsed ? "1px solid" + props.theme.text : ""};
  transition: border-color 250ms ease-in-out, color 250ms ease-in-out;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    a {
      color: ${(props) => props.theme.linkHover};
    }
  }

  &:first-of-type {
    border-left: ${(props) =>
      props.$isCollapsed ? "0" : "1px solid" + props.theme.text};
    border-top: ${(props) =>
      props.$isCollapsed ? "1px solid" + props.theme.text : "0"};
  }

  &:last-of-type {
    border-right: none;
    border-top: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.text};
  transition: color 250ms ease-in-out;
  display: block;
  width: 100%;
  padding: 1em;

  &:hover {
    color: ${(props) => props.theme.accent};
  }
`;

export const AccessibilityPageLink = styled(Link)`
  background: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.textSecondary};
  margin-left: auto;
  margin: 0.5em 0.5em 0.5em auto;
  border: ${(props) =>
    props.theme.accentBorders
      ? `1px solid ${props.theme.textSecondary}`
      : "none"};
  transition: background 250ms ease-in-out, color 250ms ease-in-out;
  text-decoration: none;
  padding: 0.5em;
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.accentHover};
    color: ${(props) => props.theme.textSecondary};
  }
`;

export const HamburgerMenu = styled.div`
  background: ${(props) => props.theme.background};
  display: ${(props) => (props.$isCollapsed ? "flex" : "none")};
  color: ${(props) => props.theme.text};
  font-size: 24px;
  margin: 0 auto;
  cursor: pointer;
  align-items: center;
  min-height: 60px;

  @media (max-width: 768px) {
    display: flex;
  }
`;
