import { Paths } from "../AppRoutes";
import PrimaryButton from "../elements/PrimaryButton";
import {
  Card,
  Container,
  DatabaseCard,
  HeroContainer,
  HomeContainer,
} from "./Home.styled";

const Home = () => {
  return (
    <HomeContainer>
      <HeroContainer>
        <Card>
          <p>Welcome to</p>
          <h1>Assistance Dogs Global</h1>
        </Card>
      </HeroContainer>
      <Container $gridArea="lawdatabase">
        <DatabaseCard>
          <h1>Countries</h1>
          <p>Unsure about what rights you have as an Assistance Dog Handler?</p>
          <p>Traveling away from home and unsure about the local laws?</p>
          <p>
            Our database has an ever-increasing collection of laws for every
            country, state and region.
          </p>
          <a href={Paths.COUNTRIES}>View country</a>
        </DatabaseCard>
      </Container>
      <Container $gridArea="accessibilitydatabase">
        <DatabaseCard>
          <h1>Accessibility database - Under construction</h1>
          <p>Looking for locations that welcome Assistance Dogs?</p>
          <p>Not every place is as welcoming as the next</p>
          <p>
            Take a look at our database to find places near you where you won't
            have issues!
          </p>
          <PrimaryButton disabled={true}>View database</PrimaryButton>
        </DatabaseCard>
      </Container>
      <Container $gridArea="articles">
        <Card>
          <h1>Articles</h1>
        </Card>
      </Container>
      <Container $gridArea="community">
        <DatabaseCard>
          <h1>Community</h1>
          <p>
            Join our Discord community where you can meet friends, get advice
            and learn!
          </p>
          <PrimaryButton>Join Discord</PrimaryButton>
        </DatabaseCard>
      </Container>
    </HomeContainer>
  );
};

export default Home;
