import styled from "styled-components";
import { devices } from "../../media-breakpoints";

export const CommunityContainer = styled.main`
  display: grid;
  grid-template-areas:
    "CommunityInfo"
    "CommunityRules"
    "CommunityTriggerTopics";
  margin: 0.5em;

  @media only screen and (${devices.lg}) {
    margin: 4em 2em;

    grid-template-areas:
      "CommunityInfo CommunityRules"
      "CommunityInfo CommunityTriggerTopics";
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 1em;
`;

const Container = styled.div`
  padding: 1em;
`;

export const CommunityInfo = styled(Container)`
  grid-area: CommunityInfo;
`;

export const CommunityRules = styled(Container)`
  grid-area: CommunityRules;
`;

export const CommunityTriggerTopics = styled(Container)`
  grid-area: CommunityTriggerTopics;
`;
