import { StyledSlider, StyledThumb, StyledTrack } from "./Slider.styled";

const Slider = ({ value = 25, onChange }) => (
    <StyledSlider
      value={value}
      min={8}
      max={48}
      renderTrack={Track}
      renderThumb={Thumb}
      onChange={onChange}
    />
);

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;
const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

export default Slider;
