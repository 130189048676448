// import AccessibilityDatabase from "./components/AccessibilityDatabase";
import Article from "./components/article/Article";
import Articles from "./components/article/Articles";
import Community from "./components/community/Community";
import CommunityTriggerTopics from "./components/community/CommunityTriggerTopics";
import CommunityRules from "./components/community/CommunityRules";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Countries from "./components/law/Countries";
import Country from "./components/law/Country";
import Accessibility from "./components/accessibility/Accessibility";

const withLayout = (component) => <Layout>{component}</Layout>;

export const Paths = {
  HOME: "/",
  LAWS_DB: "/laws",
  ACCESSIBILITY_DB: "/accessibility-database",
  ARTICLES: "/articles",
  ARTICLE: "/articles/:id",
  COMMUNITY: "/community",
  COMMUNITY_RULES: "/community/rules",
  COMMUNITY_TRIGGER_TOPICS: "/community/trigger-topics",
  COUNTRIES: "/countries",
  COUNTRY: "/countries/country/:name",
  ACCESSIBILITY: "/accessibility",
};

export const AppRoutes = [
  {
    name: "Home",
    path: Paths.HOME,
    index: true,
    showInNav: false,
    element: withLayout(<Home />),
  },
  // {
  //   name: "Laws",
  //   path: Paths.LAWS_DB,
  //   index: false,
  //   showInNav: false,
  //   element: withLayout(<LawDatabase />),
  // },
  {
    name: "Countries",
    path: Paths.COUNTRIES,
    index: false,
    showInNav: true,
    element: withLayout(<Countries />),
  },
  {
    name: "Country",
    path: `${Paths.COUNTRIES}/:name`,
    index: false,
    showInNav: false,
    element: withLayout(<Country />),
  },
  // {
  //   name: "Accessibility",
  //   path: Paths.ACCESSIBILITY_DB,
  //   index: false,
  //   showInNav: true,
  //   element: withLayout(<AccessibilityDatabase />),
  // },
  {
    name: "Articles",
    path: Paths.ARTICLES,
    index: false,
    showInNav: true,
    element: withLayout(<Articles />),
  },
  {
    name: "Article",
    path: Paths.ARTICLE,
    index: false,
    showInNav: false,
    element: withLayout(<Article />),
  },
  {
    name: "Community",
    path: Paths.COMMUNITY,
    index: false,
    showInNav: true,
    element: withLayout(<Community />),
  },
  {
    name: "Rules",
    path: Paths.COMMUNITY_RULES,
    index: false,
    showInNav: false,
    element: withLayout(<CommunityRules />),
  },
  {
    name: "Trigger Topics",
    path: Paths.COMMUNITY_TRIGGER_TOPICS,
    index: false,
    showInNav: false,
    element: withLayout(<CommunityTriggerTopics />),
  },
  {
    name: "Accessbility",
    path: Paths.ACCESSIBILITY,
    index: false,
    showInNav: false,
    element: withLayout(<Accessibility />),
  },
];

export default AppRoutes;
