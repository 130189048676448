import {
  NotFoundContainer,
  NotFoundText,
} from "./NotFoundPage.styled";

const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <NotFoundText>Page Not found</NotFoundText>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
