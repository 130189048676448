import styled from "styled-components";
import { devices } from "../media-breakpoints";

export const HomeContainer = styled.main`
  display: grid;
  grid-template-areas:
    "hero"
    "lawdatabase"
    "accessibilitydatabase"
    "articles"
    "community";
  padding: 0.5em;

  @media only screen and (${devices.lg}) {
    padding: 4em 2em;

    grid-template-areas:
      "hero lawdatabase"
      "hero accessibilitydatabase"
      "articles community";
  }
`;

export const Container = styled.div`
  grid-area: ${(props) => props.$gridArea};
  padding: 1em;
`;

export const HeroContainer = styled(Container)`
  grid-area: hero;

  div {
    height: 100%;
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em;
`;

export const DatabaseCard = styled(Card)`
  a {
    background: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.textSecondary};
    margin-left: auto;
    /* margin: 0.5em 0.5em 0.5em auto; */
    border: none;
    transition: background 250ms ease-in-out, color 250ms ease-in-out;
    padding: 0.5em;
    text-decoration: none;
    display: inline-block;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.accentHover};
    }
  }
`;