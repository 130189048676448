import DOMPurify from "dompurify";
import { Paths } from "../../AppRoutes";
import {
  Card,
  CommunityContainer,
  CommunityInfo,
  CommunityRules,
  CommunityTriggerTopics,
} from "./Community.styled";
import { useGetCommunityPageQuery } from "./CommunityApi";
import LoadingPage from "../LoadingPage";
import PrimaryButton from "../../elements/PrimaryButton";
import { Link } from "react-router-dom";

const Community = () => {
  const { data, isLoading } = useGetCommunityPageQuery();

  return isLoading ? (
    <LoadingPage />
  ) : (
    <CommunityContainer>
      <CommunityInfo>
        <Card>
          <h1>Community</h1>
          {data !== undefined &&
            data.body !== undefined &&
            (data.body || []).map((bodySection, idx) => {
              if (
                bodySection.sectionType === 0 &&
                bodySection.content !== undefined &&
                bodySection.content.length > 0
              )
                return <h2 key={idx}>{bodySection.content}</h2>;
              else if (
                bodySection.sectionType === 1 &&
                bodySection.content !== undefined &&
                bodySection.content.length > 0
              )
                return (
                  <div
                    key={idx}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(bodySection.content),
                    }}
                  ></div>
                );
              else {
                return (
                  <h2 key={idx}>implement else!! look in console, #{idx}</h2>
                );
              }
            })}
          <PrimaryButton>Join Discord Community</PrimaryButton>
        </Card>
      </CommunityInfo>
      <CommunityRules>
        <Card>
          <Link to={Paths.COMMUNITY_RULES}>Community Rules</Link>
        </Card>
      </CommunityRules>
      <CommunityTriggerTopics>
        <Card>
          <Link to={Paths.COMMUNITY_TRIGGER_TOPICS}>Community Trigger topics</Link>
        </Card>
      </CommunityTriggerTopics>
    </CommunityContainer>
  );
};

export default Community;
