import styled from "styled-components";
import { cmsApiConfig } from "../config";

export const ImageCrops = {
  Portrait: {
    alias: "Portrait",
    height: 500,
    width: 355,
  },
  List: {
    alias: "List",
    height: 250,
    width: 250,
  },
  Banner: {
    alias: "Banner",
    height: 250,
    width: 1000,
  },
};

export const renderImage = (image, screenWidth, cropToUse = undefined) => {
  let src = `${cmsApiConfig.baseUrl}${image.content.src}`;
  let height = image.height;
  let width = image.width;
  let selectedCrop = undefined;

  if (screenWidth < 410) {
    selectedCrop = image.content.crops.find(
      (crop) => crop.alias === ImageCrops.List.alias
    );
  }
  if (selectedCrop === undefined) {
    selectedCrop = image.content.crops.find((crop) =>
      cropToUse !== undefined
        ? crop.alias === cropToUse
        : crop.alias === ImageCrops.Portrait.alias
    );
  }
  if (selectedCrop !== undefined) {
    src = `${cmsApiConfig.baseUrl}${selectedCrop.src}`;
    height = selectedCrop.height;
    width = selectedCrop.width;
  }

  return (
    <ImageWrapper>
      <ImagePlaceHolder $height={height} $width={width}>
        <img
          src={src}
          height={height}
          width={width}
          alt={image.content.altText}
          aria-describedby={`${image.id}-${image.name}-description`}
        ></img>
      </ImagePlaceHolder>
      <ImageDescription
        id={`${image.id}-${image.name}-description`}
        $width={width}
      >
        {image.content.altText}
      </ImageDescription>
    </ImageWrapper>
  );
};

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

export const ImagePlaceHolder = styled.div`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: gray;
`;

const ImageDescription = styled.p`
  width: ${(props) => props.$width}px;
  font-style: italic;
  font-size: 14px;
  min-height: 90px;
  margin: 0;
`;
