import {
  ArticleCard,
  ArticleLink,
  ArticlesContainer,
} from "./Articles.styled";
import { useGetArticlesQuery } from "./ArticleApi";
import LoadingPage from "../LoadingPage";
import { renderImage } from "../../helpers/imageHelper";
import useWindowDimensions from "../../helpers/screenSizeHelper";

const Articles = () => {
  const { data: articles, isLoading } = useGetArticlesQuery();
  const { screenWidth } = useWindowDimensions();

  return isLoading ? (
    <LoadingPage />
  ) : (
    <ArticlesContainer>
      {(articles || []).map((article) => {
        return (
          <ArticleCard key={article.id}>
            <ArticleLink to={`/articles/${article.id}`}>
              {renderImage(article.image, screenWidth)}
              <h1>{article.title.length > 0 ? article.title : article.name}</h1>
              <p>{article.description}</p>
            </ArticleLink>
          </ArticleCard>
        );
      })}
    </ArticlesContainer>
  );
};

export default Articles;
