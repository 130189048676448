import { configureStore } from "@reduxjs/toolkit";
import { articleApi } from "../components/article/ArticleApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { communityApi } from "../components/community/CommunityApi";
import { lawApi } from "../components/law/LawApi";
import AccessibilityReducer, {
  name as accessibilityReducerName,
} from "../components/accessibility/AccessibilityReducer";
import { loadState, saveState } from "./persistState";

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    [articleApi.reducerPath]: articleApi.reducer,
    [communityApi.reducerPath]: communityApi.reducer,
    [lawApi.reducerPath]: lawApi.reducer,
    [accessibilityReducerName]: AccessibilityReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      articleApi.middleware,
      communityApi.middleware,
      lawApi.middleware
    ),
  preloadedState,
});

store.subscribe(() => {
  saveState({
    [accessibilityReducerName]: store.getState()[accessibilityReducerName],
  });
});

setupListeners(store.dispatch);
