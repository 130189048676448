import { Link } from "react-router-dom";
import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 0.25em 0;
`;

export const Spacer = styled.span`
  margin: 0 0.25em;
  color: ${(props) => props.theme.text};
`;

export const BreadcrumbLink = styled(Link)`
  color: ${(props) => props.theme.text};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
