import useBreadcrumbs from "use-react-router-breadcrumbs";
import {
  BreadcrumbContainer,
  BreadcrumbLink,
  Spacer,
} from "./Breadcrumbs.styled";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map((breadcrumb, idx) => (
        <div key={breadcrumb.key}>
          <Spacer>/</Spacer>
          <BreadcrumbLink to={breadcrumb.key}>
            {decodeURIComponent(breadcrumb.breadcrumb.props.children)}
          </BreadcrumbLink>
        </div>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;
