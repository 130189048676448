import styled from "styled-components";

export const NotFoundContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const NotFoundText = styled.h1`
  margin: 1em 0;
`;