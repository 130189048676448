import styled from "styled-components";
import { devices } from "../../media-breakpoints";

export const CommunityRulesContainer = styled.main`
margin: 0.5em;

@media only screen and (${devices.lg}) {
  margin: 4em 2em;
}
`;

export const RulesContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

export const RuleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Chevron = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;

  transition: transform 250ms ease-in-out;

  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }

  &.expanded {
    transform: rotate(180deg);
  }
`;

export const RuleBody = styled.div`
  max-height: 0;
  transition: max-height 250ms ease-in-out;
  overflow: hidden;

  &.expanded {
    max-height: 100000px;
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em 1em;
  margin: 0.5em 0;

  &:first-of-type {
    margin-top: 0;
    margin-bottom: 2em;
  }
`;
