import { useEffect, useState } from "react";
import LoadingPage from "../LoadingPage";
import {
  useGetCountriesQuery,
  useGetLawsByRegionIdQuery,
  useLazyGetLawsByCountryIdQuery,
  useLazyGetRegionsByCountryIdQuery,
} from "./LawApi";
import {
  Card,
  CardHeader,
  CountryContainer,
  EntryRequirementPill,
  LawBody,
  LawBodyHeader,
  LawCard,
  LawHeader,
  LawTextSection,
  LawTextSectionHeader,
} from "./Country.styled";
import { useParams } from "react-router";
import DOMPurify from "dompurify";
import classNames from "classnames";
import NotFoundPage from "../NotFoundPage";
import Breadcrumbs from "../../elements/Breadcrumbs";
import { LocationDropdown } from "./Countries.styled";

const Country = () => {
  const { name } = useParams();
  const countryName = decodeURIComponent(name);
  const [expandedLaws, setExpandedLaws] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(-1);

  const { data: dataCountries, isLoading } = useGetCountriesQuery();

  const getSelectedCountry = () => {
    if (dataCountries === undefined) return undefined;
    let selectedCountry = dataCountries.find(
      (country) => country.name === countryName
    );

    return (
      selectedCountry ??
      dataCountries.find(
        (country) =>
          country.threeDigitIso === countryName ||
          country.twoDigitIso === countryName
      )
    );
  };

  const selectedCountry = getSelectedCountry();

  const [getCountryLaws, countryLawsResult] = useLazyGetLawsByCountryIdQuery();
  const { data: countryLaws, isLoading: isLoadingCountryLaws } =
    countryLawsResult;

  const [getRegions, regionsResult] = useLazyGetRegionsByCountryIdQuery();
  const { data: dataRegions, isLoading: isLoadingRegions } = regionsResult;

  const { data: regionLaws } = useGetLawsByRegionIdQuery(selectedRegion, {
    skip: selectedRegion === null || selectedRegion === undefined,
  });

  useEffect(() => {
    if (
      selectedCountry !== undefined &&
      countryLaws === undefined &&
      !isLoadingCountryLaws
    ) {
      getCountryLaws(selectedCountry.id);
    }

    if (
      selectedCountry !== undefined &&
      dataRegions === undefined &&
      !isLoadingRegions
    ) {
      getRegions(selectedCountry.id);
    }
  });

  const regions = [
    { id: -1, name: "Choose an option", regionTypeName: "Regional" },
    ...[...(dataRegions || [])],
  ];

  const onChangeRegion = (e) => {
    setSelectedRegion(e);
  };

  const expandLaw = (id) => {
    var index = expandedLaws.findIndex((lawId) => lawId === id);
    if (index === -1) {
      setExpandedLaws([].concat(expandedLaws, id));
    } else {
      setExpandedLaws(expandedLaws.filter((lawId) => lawId !== id));
    }
  };

  if (isLoading && selectedCountry === undefined) return <LoadingPage />;

  if (!isLoading && selectedCountry === undefined) return <NotFoundPage />;

  const filteredRegion = regions.find((region) => region.id === selectedRegion);

  const regionLabel = Array.from(
    new Set(
      (dataRegions || [])
        .filter(
          (region) =>
            region.regionTypeName !== undefined &&
            region.regionTypeName.length > 0
        )
        .map((region) => region.regionTypeName)
    )
  ).join("/");

  const filteredRegionLabel =
    filteredRegion !== undefined &&
    filteredRegion.id !== -1 &&
    filteredRegion.regionTypeName.length > 0
      ? filteredRegion.regionTypeName
      : (regions || { regionTypeName: "Regional" }).find(
          (region) => region.regionTypeName !== undefined
        ).regionTypeName ?? "Regional";

  return (
    <CountryContainer>
      <Breadcrumbs />
      <Card>
        <CardHeader>
          <span>{selectedCountry !== undefined ? selectedCountry.name : countryName}</span>
          <img src={`${process.env.PUBLIC_URL}/images/flags/${selectedCountry.twoDigitIso}.png`} alt={"The flag of " + selectedCountry.name} loading="lazy"/>
        </CardHeader>
        {dataRegions !== undefined && dataRegions.length > 0 && (
          <>
            <label>
              Select a{" "}
              {regionLabel.length > 0 ? regionLabel.toLowerCase() : "region"}
            </label>
            <LocationDropdown
              options={regions || []}
              label='name'
              id='id'
              metadata={[]}
              searchTags={false}
              selectedVal={selectedRegion}
              handleChange={(val) => onChangeRegion(val)}
              addDefaultOption={true}
            />
          </>
        )}
      </Card>

      <LawCard>
        <LawHeader onClick={() => expandLaw(-1)}>
          <h2>National information</h2>
          <p>{selectedCountry.name}</p>
        </LawHeader>
        <LawBody
          className={classNames({
            expanded: expandedLaws.some((expandedLaw) => expandedLaw === -1),
          })}>
          <LawBodyHeader>Recognized types of assistance dogs</LawBodyHeader>
          <ul>
            {(selectedCountry.recognizedTypesOfDogs || []).map((dogType) => (
              <li key={dogType}>{dogType}</li>
            ))}
          </ul>
          <LawBodyHeader>Entry requirements</LawBodyHeader>
            {(selectedCountry.entryRequirements || []).map((requirement) => (
              <EntryRequirementPill key={requirement}>{requirement}</EntryRequirementPill>
            ))}
          {(selectedCountry.entryRequirementLinks || []).length > 0 && (
              <>
                <h4>Entry requirement links</h4>
                <ul>
                  {(selectedCountry.entryRequirementLinks || []).map(
                    (link, linkIdx) => (
                      <li key={linkIdx}>
                        <a href={link.url} target={link.target}>
                          {link.name}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
          <LawBodyHeader>Requires certification for assistance dogs:</LawBodyHeader>
          <p>{selectedCountry.requiresCertification ? "Yes" : "No"}</p>
          <h3>Public access rights for assistance dogs in training: </h3>
          <p>
            {selectedCountry.paAditRights !== undefined &&
            selectedCountry.paAditRights.length > 0
              ? selectedCountry.paAditRights[0]
              : "-"}
          </p>
          {selectedCountry.otherInfo.length > 0 && (
            <>
              <LawBodyHeader>Other info</LawBodyHeader>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedCountry.otherInfo),
                }}></div>
            </>
          )}
        </LawBody>
      </LawCard>
      {selectedRegion !== -1 && filteredRegion !== undefined && (
        <LawCard className='region'>
          <LawHeader onClick={() => expandLaw(-2)}>
            <h2>{filteredRegionLabel} information</h2>
            <p>{filteredRegion.name}</p>
          </LawHeader>
          <LawBody
            className={classNames({
              expanded: expandedLaws.some((expandedLaw) => expandedLaw === -2),
            })}>
            {(filteredRegion.recognizedTypesOfDogs || []).length > 0 && (
              <>
                <LawBodyHeader>Recognized types of assistance dogs</LawBodyHeader>
                <ul>
                  {(filteredRegion.recognizedTypesOfDogs || []).map(
                    (dogType) => (
                      <li key={dogType}>{dogType}</li>
                    )
                  )}
                </ul>
              </>
            )}
            {(filteredRegion.entryRequirements || []).length > 0 && (
              <>
                <LawBodyHeader>Entry requirements</LawBodyHeader>
                <ul>
                  {(filteredRegion.entryRequirements || []).map(
                    (requirement) => (
                      <li key={requirement}>{requirement}</li>
                    )
                  )}
                </ul>
              </>
            )}
            {(filteredRegion.entryRequirementLinks.length || []) > 0 && (
                <>
                  <h4>Entry requirement links</h4>
                  <ul>
                    {(filteredRegion.entryRequirementLinks || []).map(
                      (link, linkIdx) => (
                        <li key={linkIdx}>
                          <a href={link.url} target={link.target}>
                            {link.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </>
              )}
            <LawBodyHeader>Requires certification for assistance dogs:</LawBodyHeader>
            <p>{filteredRegion.requiresCertification ? "Yes" : "No"}</p>
            {(filteredRegion.paAditRights || []).length > 0 && (
              <>
                <LawBodyHeader>Public access rights for assistance dogs in training:</LawBodyHeader>
                <p>
                  {filteredRegion.paAditRights !== undefined &&
                  filteredRegion.paAditRights.length > 0
                    ? filteredRegion.paAditRights[0]
                    : "-"}
                </p>
              </>
            )}
            {filteredRegion.otherInfo.length > 0 && (
              <>
                <LawBodyHeader>Other info</LawBodyHeader>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(filteredRegion.otherInfo),
                  }}></div>
              </>
            )}
          </LawBody>
        </LawCard>
      )}
      {(countryLaws || []).concat(regionLaws || []).map((law) => {
        var dates = null;

        if (new Date(law.startDate).getFullYear() !== 1)
          dates = new Date(law.startDate).toISOString().split("T")[0];

        var endDate = new Date(law.endDate);
        if (endDate.getFullYear() !== 1)
          dates += ` - ${endDate.toISOString().split("T")[0]}`;

        return (
          <LawCard
            key={law.id}
            className={classNames({
              region: law.parentId !== selectedCountry.id,
            })}
            onClick={() => expandLaw(law.id)}>
            <LawHeader>
              <h2>{law.header}</h2>
              <p>{law.parentName}</p>
            </LawHeader>
            <LawBody
              className={classNames({
                expanded: expandedLaws.some(
                  (expandedLaw) => expandedLaw === law.id
                ),
              })}>
              <p>{dates}</p>
              {law.body.length > 0 && (
                <>
                  <LawTextSectionHeader>Body</LawTextSectionHeader>
                  <LawTextSection
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(law.body),
                    }}></LawTextSection>
                </>
              )}
              {law.summary.length > 0 && (
                <>
                  <LawTextSectionHeader>Summary</LawTextSectionHeader>
                  <LawTextSection
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(law.summary),
                    }}></LawTextSection>
                </>
              )}
              <div>
                <p>Links</p>
                <div>
                  {law.link.map((link) => (
                    <a key={link.url} href={link.url}>
                      {link.name}
                    </a>
                  ))}
                </div>
              </div>
            </LawBody>
          </LawCard>
        );
      })}
    </CountryContainer>
  );
};

export default Country;
