import React from "react";
import NavMenu from "./NavMenu";
import { ThemeProvider } from "styled-components";
import { themes } from "../themes";
import { ContentWrapper, GlobalStyle, PageWrapper } from "./Layout.styled";
import { useSelector } from "react-redux";
const Layout = (props) => {
  const accessibility = useSelector((state) => state.accessibility);
  const { theme: themeName, fontSize, fontFamilyHeading, fontFamilyText  } = accessibility;
  const theme = themes[themeName];
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle $fontFamilyHeading={fontFamilyHeading} $fontFamilyText={fontFamilyText} $fontSize={fontSize} />
      <PageWrapper>
        <NavMenu onToggleTheme={() => {}} />
        <ContentWrapper>{props.children}</ContentWrapper>
      </PageWrapper>
    </ThemeProvider>
  );
};

export default Layout;
