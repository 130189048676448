import styled, {keyframes} from "styled-components";

export const LoadingContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingText = styled.h1`
  margin: 1em 0;
`;

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const LoadingSpinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 1em 0;
  font-size: 2.5rem;
  
  &::after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${props => props.theme.text};
    border-color: ${props => props.theme.text} transparent ${props => props.theme.text} transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;
