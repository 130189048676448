import styled, { createGlobalStyle } from "styled-components";
import OpenSansMedium from "../fonts/OpenSans-Medium.ttf";
import InterMedium from "../fonts/Inter-Medium.woff";
import FjallaOne from "../fonts/FjallaOne-Regular.ttf";
import OpenDyslexic from "../fonts/OpenDyslexic-Regular.otf";
import bg from "../images/bg1440p.svg";

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  padding-top: 60px;
  height: 100%;
`;

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  body {
    width: 100dvw;
    height: 100dvh;
    max-width:100%;
    max-height: 100%;
    background: url(${bg}) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
  }

  @font-face {
    font-family: 'OpenSans-Medium';
    src:
      local('Open Sans Medium'),
      url(${OpenSansMedium}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'InterMedium';
    src:
      local('Inter Medium'),
      url(${InterMedium}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FjallaOne';
    src:
      local('Fjalla One'),
      url(${FjallaOne}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'OpenDyslexic';
    src:
      local('OpenDyslexic'),
      url(${OpenDyslexic}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  p, a, span, div, button, li {
    color: ${(props) => props.theme.text};
    transition: color 250ms ease-in-out;
    font-family: ${(props) => props.$fontFamilyText};    
  }
  
  * {
   font-size: ${(props) => props.$fontSize}px;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.$fontFamilyHeading};    
   }

 `;
