import SearchableDropdown from "../../elements/SearchableDropdown";
import { themes } from "../../themes";
import { AccessibilityContainer, Card } from "./Accessibility.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  setFontFamilyHeading,
  setFontFamilyText,
  setFontSize,
  setTheme,
} from "./AccessibilityReducer";
import { useState } from "react";
import PrimaryButton from "../../elements/PrimaryButton";
import Slider from "../../elements/Slider";

const Accessibility = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.accessibility.theme);
  const fontFamilyHeading = useSelector(
    (state) => state.accessibility.fontFamilyHeading
  );
  const fontFamilyText = useSelector(
    (state) => state.accessibility.fontFamilyText
  );
  const fontSize = useSelector((state) => state.accessibility.fontSize);
  const fonts = [
    {
      name: "InterMedium",
      label: "Inter",
    },
    {
      name: "OpenSans-Medium",
      label: "OpenSans",
    },
    {
      name: "FjallaOne",
      label: "Fjalla One",
    },
    {
      name: "OpenDyslexic",
      label: "OpenDyslexic",
    },
    {
      name: "Tahoma",
      label: "Tahoma",
    },
    {
      name: "Calibri",
      label: "Calibri",
    },
    {
      name: "Arial",
      label: "Arial",
    },
    {
      name: "Verdana",
      label: "Verdana",
    },
    {
      name: "Times New Roman",
      label: "Times New Roman",
    },
  ];

  const [tempFontFamilyHeading, setTempFontFamilyHeading] =
    useState(fontFamilyHeading);
  const [tempFontFamilyText, setTempFontFamilyText] = useState(fontFamilyText);
  const [tempFontSize, setTempFontSize] = useState(fontSize);

  const colorThemes = Object.values(themes);

  return (
    <AccessibilityContainer>
      <Card>
        <h1>Accessibility settings</h1>
      </Card>
      <Card>
        <h2>Color theme</h2>
        {/* <p>Use one our default themes, or configure your own!</p> */}
        <SearchableDropdown
          options={colorThemes || []}
          label='label'
          id='name'
          metadata={[]}
          searchTags={false}
          selectedVal={theme}
          handleChange={(val) => dispatch(setTheme(val))}
          addDefaultOption={false}
        />
      </Card>
      <Card>
        <h2>Fonts</h2>
        <h3>Headings</h3>
        <SearchableDropdown
          options={fonts || []}
          label='label'
          id='name'
          metadata={[]}
          searchTags={false}
          selectedVal={tempFontFamilyHeading}
          handleChange={(val) => {
            setTempFontFamilyHeading(val);
          }}
          addDefaultOption={false}
        />
        <h3>Texts</h3>
        <SearchableDropdown
          options={fonts || []}
          label='label'
          id='name'
          metadata={[]}
          searchTags={false}
          selectedVal={tempFontFamilyText}
          handleChange={(val) => {
            setTempFontFamilyText(val);
          }}
          addDefaultOption={false}
        />
        <h2>Font size</h2>
        <Slider value={tempFontSize} onChange={(e) => setTempFontSize(e)} />
        <h2 style={{ fontFamily: tempFontFamilyHeading }}>Example</h2>
        <p
          style={{
            fontFamily: tempFontFamilyText,
            fontSize: tempFontSize + "px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
          turpis sed urna dictum elementum. Etiam nunc nibh, tristique ac nibh
          at, vestibulum malesuada metus. Donec sit amet tellus ut nibh
          hendrerit consequat. Aliquam diam ipsum, tempus in massa in, facilisis
          feugiat enim. Phasellus dignissim auctor ex, in semper nulla lacinia
          ac. Phasellus nec elit posuere, pharetra tellus nec, elementum felis.
          Mauris non diam eget dui rutrum fermentum vel at lorem. Nulla ornare a
          felis eu suscipit.
        </p>
        <PrimaryButton
          onClick={() => {
            dispatch(setFontFamilyHeading(tempFontFamilyHeading));
            dispatch(setFontFamilyText(tempFontFamilyText));
            dispatch(setFontSize(tempFontSize));
          }}
        >
          Save
        </PrimaryButton>
      </Card>
    </AccessibilityContainer>
  );
};

export default Accessibility;
