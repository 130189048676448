import styled from "styled-components";
import { devices } from "../../media-breakpoints";
import { Link } from "react-router-dom";

export const ArticlesContainer = styled.main`
  display: grid;
  /* grid-template-columns: auto; */
  padding: 0.5em;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));

  @media only screen and (${devices.lg}) {
    padding-bottom: 2em;
    padding: 4em 2em;

    /* grid-template-columns: auto auto auto; */
  }

  @media only screen and (max-width: 410px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 4px;
  padding: 0.5em;
`;

export const ArticleCard = styled(Card)`
  padding: 1em;
`;

export const ArticleLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    color: ${(props) => props.theme.linkHover};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ImagePlaceHolder = styled.div`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: gray;
`;
