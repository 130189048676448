import { createSlice } from "@reduxjs/toolkit";
import { darkMode, lightMode } from "../../themes";

export const name = "accessibility";

const prefersLight = window.matchMedia("(prefers-color-scheme: light)").matches;

const defaultTheme = !prefersLight ? lightMode : darkMode;

const initialState = {
  theme: defaultTheme.name,
  fontFamilyHeading: "InterMedium",
  fontFamilyText: "OpenSans-Medium",
  fontSize: 16,
};

const accessibilitySlice = createSlice({
  name,
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setFontFamilyHeading(state, action) {
      state.fontFamilyHeading = action.payload;
    },
    setFontFamilyText(state, action) {
      state.fontFamilyText = action.payload;
    },
    setFontSize(state, action) {
      state.fontSize = action.payload;
    },
  },
});

export const { setTheme, setFontFamilyHeading, setFontFamilyText, setFontSize } =
  accessibilitySlice.actions;

export default accessibilitySlice.reducer;
